<template>
  <tr
    v-show="!item.parentId || selectedRow"
    @dblclick.stop="emitInput(item)"
    class="pointer">
    <td v-if="hidden.id">
      <span v-show="!item.parentId">
        <fa
          v-if="selectedRow === item.id"
          icon="trash"
          class="text-danger pointer"
          @click="destroyOrder(item.id)">
        </fa>
        {{ item.id }}
      </span>
    </td>
    <td v-if="hidden.name">
      <span v-for="ledger in item.ledgers" :key="ledger.id">
        <small>
          <span :class="{
            'text-success': ledger.approvedAt,
            'text-warning': !ledger.approvedAt
          }">
            {{ledger.account.code}}: {{ledger.account.name}}
          </span>
          <span v-if="ledger.stockId" class="text-info">
            <fa icon="link"></fa>
          </span>
        </small>
        <br>
      </span>
      <span style="white-space: pre-line;">{{ item.name }}</span>
      <span v-for="stock in item.stocks" :key="stock.id" class="text-primary">
        <br>
        <small>
          <em>{{ stock.inventory.code }} ({{stock.inventory.name}})</em>
        </small>
      </span>
    </td>
    <td v-if="hidden.qty">
      <span v-for="ledger in item.ledgers" :key="ledger.id"><br></span>
      <span :class="{'text-success': item.qty > 0, 'text-danger': item.qty < 0}">
        {{ Number(item.qty) | comma }}
      </span>
      <span v-if="item.unit">{{ item.unit.name }}</span>
      <small v-for="stock in item.stocks" :key="stock.id">
        <br>
        <fa
          icon="print"
          @click.stop="printTag(stock.parentId, 1)"
          :class="{'text-warning': stock.isPrinted}">
        </fa>
        <span :class="{'text-success': stock.approvedAt, 'text-warning': !stock.approvedAt}">
          TAG: {{stock.id}}
        </span>
        (
          <span
            :class="{
              'text-success': stock.qty > 0,
              'text-danger': stock.qty < 0
              }">
            {{stock.qty | comma}}
          </span>
        )
        <span>
          {{stock.inventory.unit.name}}
        </span>
        <span class="text-secondary">
          ({{stock.parentId}})
        </span>
      </small>
    </td>
    <td v-if="hidden.price">
      <span v-for="ledger in item.ledgers" :key="ledger.id"><br></span>
      <span v-if="item.price > 0">{{ item.price | comma  }}</span>
      <span v-else>-</span>
      <small v-if="item.discount" class="text-primary">
        <br>{{item.discount}} ({{item.discountPrice}})
      </small>
    </td>
    <td v-if="hidden.totalPrice">
      <span v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}">
          {{Math.abs(ledger.amount) | comma}}
        </small>
        <br>
      </span>
      {{ item.totalPrice | comma  }}
    </td>
  </tr>
</template>

<script>
import {
  DESTROY_ORDER,
  PRINT_STOCK_TAG
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    item () {
      return this.row.item
    },
    hidden () {
      return this.row.hidden
    },
    selectedRow () {
      return this.value.find(v => v === this.item.parentId || v === this.item.id)
    }
  },
  methods: {
    emitInput (item) {
      if (item.parentId) return
      const found = this.value.indexOf(item.id)
      const arr = [...this.value]
      if (found === -1) {
        arr.push(item.id)
      } else {
        arr.splice(found, 1)
      }
      this.$emit('input', arr)
    },
    destroyOrder (id) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
      .then(() => {
        this.emitOrderUpdated()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    printTag (id, qty) {
      this.$apollo.mutate({
        mutation: PRINT_STOCK_TAG(this.templateType),
        variables: {
          docType: this.docType,
          stockId: id,
          qty: +qty
        }
      })
      .then(res => {
        const found = this.row.item.stocks.find(v => v.id === id)
        if (found) found.isPrinted = true
        this.$toasted.global.success(res.data.printStockTag)
      })
      .catch(this.$toasted.global.error)
    },
    emitOrderUpdated () {
      this.$emit('updated', null)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
