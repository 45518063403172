<template>
  <div class="form-row">
    <sgv-input-select
      disabled
      class="col-5 col-md-6"
      :options="[{text:'มูลค่าใบกำกับภาษีเดิม'}]"
      select="text"
      :value="'มูลค่าใบกำกับภาษีเดิม'">
      <template slot-scope="option">
        {{option.text}}
      </template>
    </sgv-input-select>

    <sgv-input-currency
      class="col"
      placeholder="ราคา"
      v-model="formData.price"
      :validations="[
        {text: 'required!', value: $v.formData.price.$dirty && !$v.formData.price.required}
      ]"
      @keyup.enter.native="createOrder">
    </sgv-input-currency>

    <div class="col-auto">
      <button class="btn btn-success" @click="createOrder">
        <fa icon="plus"></fa>
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CREATE_ORDER_INVOICE_PRICE } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      formType: 'invoicePrice',
      formData: {
        price: 0
      }
    }
  },
  validations: {
    formData: {
      price: { required },
    }
  },
  methods: {
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER_INVOICE_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: this.formData
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.formData.price = 0
      this.$v.formData.$reset()
    }
  },
  created () {
    this.setDefault()
  }
}
</script>

<style lang="css" scoped>
</style>
