<template>
  <sgv-table
    :items="orders"
    :headers="headers"
    :filter.sync="filter"
    :toolbars="toolbars"
    :options.sync="options">

    <template slot="option" v-if="options.toolbar === 'itemData'">
      <DetailOrderItemInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderItemInput>

      <DetailOrderExchangeInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderExchangeInput>

      <DetailOrderPriceDiffInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :configs="configs">
      </DetailOrderPriceDiffInput>

      <DetailOrderExpenseInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :configs="configs">
      </DetailOrderExpenseInput>

      <DetailOrderDiscountPriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :configs="configs"
        :orders="orders">
      </DetailOrderDiscountPriceInput>

      <DetailOrderInvoicePriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :configs="configs">
      </DetailOrderInvoicePriceInput>

      <DetailOrderVatPriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :configs="configs"
        :orders="orders">
      </DetailOrderVatPriceInput>

      <DetailOrderNetPriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :configs="configs"
        :orders="orders">
      </DetailOrderNetPriceInput>
    </template>

    <template slot-scope="row">
      <DetailOrderSubOrderItem
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :row="row"
        v-model="selectedRows">
      </DetailOrderSubOrderItem>
    </template>
  </sgv-table>
</template>

<script>
import { round } from 'lodash'
import DetailOrderItemInput from './DetailOrderItemInput.vue'
import DetailOrderExchangeInput from './DetailOrderExchangeInput.vue'
import DetailOrderSubOrderItem from './DetailOrderSubOrderItem.vue'
import DetailOrderExpenseInput from './DetailOrderExpenseInput.vue'
import DetailOrderDiscountPriceInput from './DetailOrderDiscountPriceInput.vue'
import DetailOrderVatPriceInput from './DetailOrderVatPriceInput.vue'
import DetailOrderNetPriceInput from './DetailOrderNetPriceInput.vue'
import DetailOrderInvoicePriceInput from './DetailOrderInvoicePriceInput.vue'
import DetailOrderPriceDiffInput from './DetailOrderPriceDiffInput.vue'
import {
  LIST_ORDER,
  WATCH_ORDER_UPDATED,
  WATCH_ORDER_DESTROYED,
  WATCH_DOC_UPDATED
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    configs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'qty', 'unit', 'price', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
      selectedRows: [],
      invoicePrice: 0
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables() {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
      update (data) {
        return [
          ...data.orders.filter(v => v.type === 'item'),
          ...data.orders.filter(v => v.type === 'exchange'),
          ...data.orders.filter(v => v.type === 'priceDiff'),
          ...data.orders.filter(v => v.type === 'expense'),
          ...data.orders.filter(v => v.type === 'discountPrice'),
          ...data.orders.filter(v => v.type === 'invoicePrice'),
          ...data.orders.filter(v => v.type === 'vatPrice'),
          ...data.orders.filter(v => v.type === 'netPrice')
        ]
      },
      subscribeToMore: [
        {
          document () {
            return WATCH_ORDER_UPDATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const orderIndex = previous.orders.findIndex(v => v.id === subscriptionData.data.orderUpdated.id)
            let newOrders

            if (orderIndex !== -1) {
              newOrders = [...previous.orders]
              newOrders[orderIndex] = subscriptionData.data.orderUpdated
            } else {
              newOrders = [
                ...previous.orders,
                subscriptionData.data.orderUpdated
              ]
            }

            return {
              ...previous,
              orders: newOrders
            }
          }
        },
        {
          document () {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const {id, parentId} = subscriptionData.data.orderDestroyed
            let newOrders = []
            if (parentId) {
              const parentIndex = previous.orders.findIndex(v => v.id === parentId)
              if (parentIndex === -1) return
              const orderIndex = previous.orders[parentIndex].children.findIndex(v => v.id === id)
              if (orderIndex === -1) return
              newOrders = [...previous.orders]
              newOrders[parentIndex].children.splice(orderIndex, 1)
            } else {
              const orderIndex = previous.orders.findIndex(v => v.id === id)
              if (orderIndex === -1) return
              newOrders = [...previous.orders]
              newOrders.splice(orderIndex, 1)
            }
            const result = {
              ...previous,
              orders: newOrders
            }
            return result
          }
        },
      ]
    },
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.$apollo.queries.orders.refetch()
        }
      },
    },
  },
  computed: {
    headers () {
      return [
        {text: 'id', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา/หน่วย', value: 'price'},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      if (this.formData.approvedAt) return []

      let arr = []
      if (!this.formData.closedAt && this.docId > 0 && this.$auth.hasRole(`doc:${this.docType}:add`)) {
        arr.push({value: 'itemData', icon: 'plus', class: 'text-success'})
      }
      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    ledgersSum () {
      return this.orders.reduce((t,v) => {
        const x = t + v.ledgers.reduce((sub, ledger) => {
          return sub + Number(ledger.amount)
        }, 0)
        return round(x, 2)
      }, 0)
    },
  },
  watch: {
    'formData.approvedAt': {
      handler () {
        this.options.toolbar = null
      }
    }
  },
  components: {
    DetailOrderSubOrderItem,
    DetailOrderExpenseInput,
    DetailOrderDiscountPriceInput,
    DetailOrderItemInput,
    DetailOrderExchangeInput,
    DetailOrderVatPriceInput,
    DetailOrderNetPriceInput,
    DetailOrderInvoicePriceInput,
    DetailOrderPriceDiffInput
  }
}
</script>

<style lang="css">
</style>
