<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="หมายเหตุ"
        v-model="formData.remark">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <DetailFormContactInput
        label="เจ้าหนี้"
        placeholder="..."
        :disabled="method !== 'add'"
        class="col-12 col-sm-8"
        select="id"
        @change="setContact"
        v-model="formData.contactId"
        :docType="docType"
        :templateType="templateType"
        :validations="[
          {text: 'required!', value: v.formData.contactId.$dirty && v.formData.contactId.$error}
        ]">
        <template slot-scope="option">
          {{option.branch && option.branch !== 'สำนักงานใหญ่' ? `สาขา: ${option.branch} --` : ''}}
          {{option.contact.code}} ({{option.contact.name}})
        </template>
      </DetailFormContactInput>

      <DetailFormInvoiceInput
        v-if="formData.contactId"
        label="ใบรับสินค้า"
        placeholder="..."
        :disabled="$auth.disabled(method)"
        class="col-12 col-sm-4"
        select="id"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        v-model="formData.parentId">
        <template slot-scope="option">
          {{option.code}}
        </template>
      </DetailFormInvoiceInput>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="ใบส่งสินค้า"
        v-model="formData.invoiceRef">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <DetailFormRepairInput
        label="ใบแจ้งสินค้า (NCR)"
        class="col-12"
        select="id"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :method="method"
        :formData="formData">
      </DetailFormRepairInput>
    </div>

    <div class="form-row">
      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="หมายเหตุเอกสาร"
        :rows="1"
        v-model="formData.docRemark">
      </sgv-input-textarea>
    </div>

    <CategoryCheck
      :docId="docId"
      :docType="docType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      :method="method"
      :isClosed="!!formData.closedAt || !!formData.approvedAt"
      v-model="formData.categories"
      @updated="emitUpdated">
    </CategoryCheck>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-4"
        label="สร้าง"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="อนุมัติ"
        :value="getState('approved')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="จบ"
        :value="getState('closed')">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
import DetailFormContactInput from './DetailFormContactInput'
import DetailFormInvoiceInput from './DetailFormInvoiceInput'
import DetailFormRepairInput from './DetailFormRepairInput'
import CategoryCheck from '@/views/doc_core/components/CategoryCheck'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      contact: null
    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    setContact (value) {
      this.contact = value
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    contact (v) {
      this.$emit('contact', v)
    }
  },
  components: {
    DetailFormContactInput,
    DetailFormInvoiceInput,
    DetailFormRepairInput,
    CategoryCheck
  }
}
</script>

<style lang="css">
</style>
