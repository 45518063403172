<template>
  <div class="form-row">
    <DetailOrderExchangeInputInventory
      ref="dropdownInventory"
      placeholder="สินค้าแลกเปลี่ยน"
      class="col-5 col-md-6"
      select="id"
      :docType="docType"
      :templateType="templateType"
      v-model="formData.docConfigId"
      :validations="[
        {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
      ]">
    </DetailOrderExchangeInputInventory>

    <sgv-input-text
      class="col"
      placeholder="จำนวน ราคา"
      v-model="qty"
      @keyup.enter.native="createOrder">
    </sgv-input-text>

    <div class="col-auto">
      <button class="btn btn-success" @click="createOrder">
        <fa icon="plus"></fa>
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderExchangeInputInventory from './DetailOrderExchangeInputInventory.vue'
import { CREATE_ORDER_EXCHANGE } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
  },
  data () {
    return {
      formType: 'exchange',
      qty: '',
      formData: {
        docConfigId: null,
        qty: 0,
        price: 0,
      }
    }
  },
  validations: {
    formData: {
      docConfigId: { required },
      qty: {
        required,
        minValue: (value) => value > 0
      },
      price: {required},
    }
  },
  methods: {
    serializeInput () {
      const arr = this.qty.split(' ')
      this.formData.qty = +arr[0]
      this.formData.price = +arr[1]
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER_EXCHANGE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: this.formData
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$refs.dropdownInventory.setFocus()
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.qty = 0
      this.formData.price = 0
      this.qty = ''
      this.$v.formData.$reset()
    }
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        if (!v) {
          this.formData.qty = 0
          this.formData.price = 0
          this.qty = ''
          this.$v.formData.$reset()
        }
      }
    },
    'qty': 'serializeInput'
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderExchangeInputInventory
  }
}
</script>

<style lang="css" scoped>
</style>
